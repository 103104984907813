import Layout from '@/layout'

const villageRouter = {
  path: '/alyWorkOrderXq',
  component: Layout,
  children: [{
    path: '/workOrderXq',
    name: 'workOrderXq',
    meta: {
      title: "工单详情"
    },
    component: () =>
      import('@/views/alyWorkOrder/workOrderXq.vue')
  },
  {
    path: '/workOrderAdd/:woid',
    name: 'workOrderAdd',
    meta: {
      title: "工单"
    },
    component: () =>
      import('@/views/alyWorkOrder/workOrderAdd.vue')
  },
  {
    path: '/myWorkOrderXq',
    name: 'myWorkOrderXq',
    meta: {
      title: "工单详情"
    },
    component: () =>
      import('@/views/alyWorkOrder/myWorkOrder/myWorkOrderXq.vue')
  },
    //     {
    //         path: '/anLuoYun/serve',
    //         name: 'serve',
    //         meta: {
    //             title: "企业服务"
    //         },
    //         component: () =>
    //             import ('@/views/anLuoYun/serve.vue')
    //     },
    //     {
    //         path: '/anLuoYun/recruit',
    //         name: 'recruit',
    //         meta: {
    //             title: "企业招聘"
    //         },
    //         component: () =>
    //             import ('@/views/anLuoYun/recruit.vue')
    //     },
    //     {
    //         path: '/anLuoYun/message',
    //         name: 'message',
    //         meta: {
    //             title: "企业资讯"
    //         },
    //         component: () =>
    //             import ('@/views/anLuoYun/message.vue')
    //     },
    //     {
    //         path: '/anLuoYun/contact',
    //         name: 'contact',
    //         meta: {
    //             title: "联系我们"
    //         },
    //         component: () =>
    //             import ('@/views/anLuoYun/contact.vue')
    //     },
  ]
}

export default villageRouter